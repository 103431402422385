/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'power-on': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M13.333 7.5v3.883l-2.917 2.925v1.525h-.833v-1.525l-2.916-2.933V7.5h6.667m0-5h-1.667v3.333H8.333V2.5H6.667v3.333h-.009A1.656 1.656 0 005 7.483v4.6L7.917 15v2.5h4.167V15L15 12.075V7.5a1.672 1.672 0 00-1.667-1.667z"/>',
    lastMod: '1734081822986.1743'
  }
})
