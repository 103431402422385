/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'objects-drag': {
    width: 16,
    height: 16,
    viewBox: '0 0 13 9',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><g data-name="Group 3207" fill="none" stroke="currentColor" stroke-linecap="round"><path pid="0" data-name="Line 571" d="M1 .5h11"/><path pid="1" data-name="Line 572" d="M1 4.5h11"/><path pid="2" data-name="Line 573" d="M1 8.5h11"/></g></g></g>',
    lastMod: '1734081822982.1743'
  }
})
