/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sms': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M16.4 2H3.6A1.6 1.6 0 002 3.6V18l3.2-3.2h11.2a1.6 1.6 0 001.6-1.6V3.6A1.6 1.6 0 0016.4 2zm0 11.2H4.536l-.936.936V3.6h12.8zM6 7.6h1.6v1.6H6zm6.4 0H14v1.6h-1.6zm-3.2 0h1.6v1.6H9.2z"/>',
    lastMod: '1734081822986.1743'
  }
})
