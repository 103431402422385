/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'time': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<g fill="none" stroke="#a8a8a8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.1"><path pid="0" d="M10.83 3.5A5 5 0 1011.5 6a5 5 0 00-.67-2.5z"/><path pid="1" d="M8.318 7.819L6.5 6V4.214"/></g>',
    lastMod: '1734081822986.1743'
  }
})
