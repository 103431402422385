/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sms-bulk': {
    width: 20.001,
    height: 20.001,
    viewBox: '0 0 20.001 20.001',
    data: '<path pid="0" d="M0 .001h20v20H0z" fill="none"/><path pid="1" d="M14.4 4.001H1.6a1.6 1.6 0 00-1.6 1.6v14.4l3.2-3.2h11.2a1.6 1.6 0 001.6-1.6v-9.6a1.6 1.6 0 00-1.6-1.6zm0 11.2H2.536l-.936.936V5.601h12.8zM4 9.601h1.6v1.6H4zm6.4 0H12v1.6h-1.6zm-3.2 0h1.6v1.6H7.2z"/><path pid="2" d="M16.407 14.801v-11.2H2a1.6 1.6 0 011.6-1.6h12.8a1.6 1.6 0 011.6 1.6v9.6a1.6 1.6 0 01-1.593 1.6z"/><path pid="3" d="M18.407 12.801v-11.2H4a1.6 1.6 0 011.6-1.6h12.8a1.6 1.6 0 011.6 1.6v9.6a1.6 1.6 0 01-1.593 1.6z"/>',
    lastMod: '1734081822986.1743'
  }
})
