/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M13 10.778h-2.25V3h-1.5v7.778H7l3 3.111zm-9 4.666V17h12v-1.556z" fill="#009bd4"/>',
    lastMod: '1734081822982.1743'
  }
})
