/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'current': {
    width: 20,
    height: 20.009,
    viewBox: '0 0 20 20.009',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M10 17.98A7.989 7.989 0 012.02 10 7.989 7.989 0 0110 2.02 7.989 7.989 0 0117.98 10c0 .195-.007.392-.021.585a5.422 5.422 0 00-1.578-.516V10A6.388 6.388 0 0010 3.62 6.388 6.388 0 003.62 10a6.388 6.388 0 006.381 6.381h.07a5.447 5.447 0 00.512 1.579c-.201.014-.397.02-.583.02zm-.486-2.387V11.2H7.003l3.6-6.8v4.4h2.4z" fill="#1a1a1a"/><path pid="2" d="M11 15.504a4.5 4.5 0 114.5 4.5 4.526 4.526 0 01-4.5-4.5zm3.079 2.166l.427-1.226h2l.427 1.226a.447.447 0 00.437.361.413.413 0 00.438-.409.712.712 0 00-.058-.266l-1.635-4.389a.62.62 0 00-.608-.418.6.6 0 00-.6.418l-1.644 4.389a.941.941 0 00-.048.266.4.4 0 00.427.409c.219 0 .352-.114.438-.361zm1.425-4.076l-.019-.057h.038l-.019.057.751 2.119h-1.5z" fill="#1a1818"/>',
    lastMod: '1734081822982.1743'
  }
})
