export default ({ app }, inject) => {
  const getApiUrls = () => {
    if (process.browser) {
      switch (window.location.hostname) {
        case 'localhost':
        case 'dev.bluconsole.com':
          return {
            baseApiUrl: 'https://dev-api.bluconsole.com',
            secondApiUrl: 'https://dev-webservice-api.bluconsole.com',
          }
        // eslint-disable-next-line default-case-last
        default:
        case 'bluconsole.com':
          return {
            baseApiUrl: 'https://api.bluconsole.com',
            secondApiUrl: 'https://webservice-api.bluconsole.com',
          }
      }
    }
    return null
  }

  inject('getApiUrls', getApiUrls)
}
