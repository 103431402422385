/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'correct-last-measurement': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<g transform="translate(-274.225 -298)"><circle pid="0" cx="6.5" cy="6.5" r="6.5" transform="translate(274.225 298)" fill="#54a054"/><path pid="1" d="M277.724 304.285l2.167 2.167 4.252-4.252" fill="none" stroke="#fff" stroke-width="1.3"/></g>',
    lastMod: '1734081822982.1743'
  }
})
