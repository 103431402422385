/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'line-solid': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 2',
    data: '<path pid="0" stroke="currentColor" stroke-width="2" d="M0 1h24"/>',
    lastMod: '1734081822982.1743'
  }
})
