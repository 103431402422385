/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'objects-hub-big': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 21',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" fill="none" stroke="#b1c1c7" stroke-miterlimit="10"><rect pid="0" x=".5" y="12.5" width="21" height="8" rx="3"/><path pid="1" stroke-linecap="round" d="M4 16.5h2.5M9 16.5h2.5M16.5 12.5V8M13.86 4.7a3.75 3.75 0 015.28 0M11.83 2.43a6.62 6.62 0 019.34 0"/></g></g>',
    lastMod: '1734081822982.1743'
  }
})
