/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'report': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M11.666 1.667H5a1.672 1.672 0 00-1.667 1.667v13.333a1.664 1.664 0 001.658 1.667H15a1.672 1.672 0 001.666-1.667v-10zm3.333 15h-10V3.334h5.834V7.5H15z" fill="#009bd4"/><path pid="2" d="M6.567 10.819h1.556v4.07H6.567zm2.655-1.375h1.556v5.444H9.222zm2.59 2.333h1.556v3.111h-1.556z" fill="#009bd4"/>',
    lastMod: '1734081822986.1743'
  }
})
