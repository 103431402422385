/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'private': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M14.8 14.8H3.6A1.6 1.6 0 012 13.2V3.6A1.6 1.6 0 013.6 2h12.8a1.6 1.6 0 011.592 1.6l.009 14.4zM3.6 13.2h11.863l.937.934V3.599H3.6zM7.891 12a.971.971 0 01-.89-.965v-2.6a.956.956 0 01.89-.89V6.431A2.041 2.041 0 019.97 4.575a2.041 2.041 0 012.081 1.856v1.114a.971.971 0 01.89.965v2.6a.971.971 0 01-.964.893zm.967-5.569v1.114h2.226V6.431a1.021 1.021 0 00-1.114-.967 1.021 1.021 0 00-1.113.968z" fill="#1a1a1a"/>',
    lastMod: '1734081822986.1743'
  }
})
