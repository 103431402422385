/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-arrow': {
    width: 7,
    height: 10,
    viewBox: '0 0 7 10',
    data: '<path pid="0" d="M1 9.332l4.317-4.316L1 .7" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.4"/>',
    lastMod: '1734081822982.1743'
  }
})
