/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-pin': {
    width: 17,
    height: 21,
    viewBox: '0 0 17 21',
    data: '<g fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.3"><path pid="0" data-name="Path 10134" d="M11.5 8.5a3 3 0 11-3-3 3 3 0 013 3z" stroke-linejoin="round"/><path pid="1" data-name="Path 10135" d="M16 8.5c0 7.142-7.5 11.25-7.5 11.25S1 15.642 1 8.5a7.5 7.5 0 1115 0z"/></g>',
    lastMod: '1734081822982.1743'
  }
})
