/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'notification-type': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M15.93 4H3.868v2H15.93zM7.079 8H3.868v2h3.211zm.846 4H3.868v2h4.058zM22.619 9.578l-2.979-2.5-1.179 1.337 2.98 2.5zm-9.493-1.163l-1.079-1.24-2.98 2.494 1.086 1.241zm2.992 2.987h-.971v3.887l3.077 1.846.485-.8-2.591-1.535zm-.323-2.591a5.83 5.83 0 105.829 5.83 5.831 5.831 0 00-5.829-5.831zm0 10.008a4.177 4.177 0 114.177-4.178 4.175 4.175 0 01-4.177 4.177z" fill="#009bd4"/>',
    lastMod: '1734081822982.1743'
  }
})
