/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alerts-active': {
    width: 18,
    height: 21.5,
    viewBox: '0 0 18 21.5',
    data: '<path pid="0" data-name="add_alert-24px (1)" d="M7.01 19.51a1.99 1.99 0 003.98 0zM9 0a1.5 1.5 0 00-1.5 1.5v1.17A6.993 6.993 0 002 9.5v6l-2 2v1h18v-1l-2-2v-6a6.993 6.993 0 00-5.5-6.83V1.5A1.5 1.5 0 009 0z" fill="#be3939"/>',
    lastMod: '1734081822982.1743'
  }
})
