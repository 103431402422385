/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hubs': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M19 6v4H4V6h15m0 10v4H4v-4h15m1-12H3a1 1 0 00-1 1v6a1 1 0 001 1h17a1 1 0 001-1V5a1 1 0 00-1-1zm0 10H3a1 1 0 00-1 1v6a1 1 0 001 1h17a1 1 0 001-1v-6a1 1 0 00-1-1z" fill="#009bd4"/>',
    lastMod: '1734081822982.1743'
  }
})
